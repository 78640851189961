import React from 'react'
import { navigate } from 'gatsby'
import { useLocalization } from 'gatsby-theme-i18n'
import Lottie from 'lottie-react'
import Theme, { Container, Layout } from '../components'
import { Button } from '../components/Button'

import requestDemo from '../data/animations/request-demo.json'

const TerminPage = (): React.ReactElement | null => {
  const { locale } = useLocalization()

  if (typeof window !== 'undefined') {
    navigate(`/${locale}/termin-buchen`, { replace: true })
    return null
  }

  return (
    <Theme>
      <Layout>
        <Container className="py-20">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-4">
            <div className="md:col-span-1">
              <h1 className="text-3xl mb-3">Die Zukunft des Payments</h1>
              <p className="font-light text-xl">
                Der Payment-Enabler für eine neue Welt - weniger
                Zahlungsausfälle, geringere Personalkosten, höhere Liquidität
                und engere Kundenbindung.{' '}
                <span className="font-normal">
                  Wie? Buchen Sie jetzt Ihre persönliche Live Demo.
                </span>
              </p>

              <div className="flex flex-col sm:flex-row gap-5 items-center mt-10">
                <Button
                  variant="secondary"
                  onClick={() => navigate(`/${locale}/termin-buchen`)}
                >
                  Jetzt live kennenlernen
                </Button>

                <Button
                  variant="link"
                  onClick={() => navigate(`/${locale}/termin-buchen`)}
                >
                  Demotermin buchen
                </Button>
              </div>

              <p className="mt-5 font-light">Es fallen keine Kosten an.</p>
            </div>

            <div className="md:col-span-1">
              <Lottie animationData={requestDemo} />
            </div>
          </div>
        </Container>
      </Layout>
    </Theme>
  )
}

export default TerminPage
